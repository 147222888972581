import { Component, DestroyRef, OnDestroy, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

@Component({
  standalone: true,
  selector: "standard-skip-nav-link",
  template: `<a class="skip-nav-link" [href]="skipNavUrl">Skip to content</a>`,
  styles: [
    `
      .skip-nav-link {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        position: absolute;

        &:focus {
          padding: 1rem;
          position: fixed;
          top: 10px;
          left: 10px;
          background: white;
          z-index: 940;
          width: auto;
          height: auto;
          clip: auto;
        }
      }
    `,
  ],
})
export class StandardSkipNavLinkComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.updateSkipNavUrl(window.location.pathname);
    router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event): void => {
        if (event instanceof NavigationEnd) {
          this.updateSkipNavUrl(event.urlAfterRedirects);
        }
      });
  }

  private destroyRef = inject(DestroyRef);
  skipNavUrl: string = "#skip-nav";

  updateSkipNavUrl(urlAfterRedirects: string) {
    const newUrl = urlAfterRedirects.split("#")[0];
    this.skipNavUrl = `${newUrl}#skip-nav`;
  }
}
