import { HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { takeUntil } from "rxjs";
import { HttpCancelService } from "../services/http-cancel.service";

export function HttpCancelInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
) {
  const httpCancelService = inject(HttpCancelService);
  const persistOnNavigate = req.params.get("__persistOnNavigate");

  if (persistOnNavigate) {
    const reqWithoutSkipCancel = req.clone({
      params: req.params.delete("__persistOnNavigate"),
    });
    return next(reqWithoutSkipCancel);
  } else {
    return next(req).pipe(
      takeUntil(httpCancelService.onCancelPendingRequests()),
    );
  }
}
