import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { SecurityActivateGuard } from "src/standard/security/security-activate.guard";
import { StandardLogService } from "src/standard/logging/logging";
import { BreezeSetupGuard } from "../breeze-setup/breeze-setup.guard";
import { SecurityService } from "src/standard/security/security.service";

export const AppGuard = (async (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const msalGuard = inject(MsalGuard);
  const breezeGuard = inject(BreezeSetupGuard);
  const log = inject(StandardLogService);
  const security = inject(SecurityService);
  const router = inject(Router);

  try {
    const isLoggedIn = await msalGuard.canActivate(next, state).toPromise();
    if (isLoggedIn !== true) {
      return isLoggedIn ?? false;
    }

    const isBreezeReady = await breezeGuard.canActivate(next, state);
    if (isBreezeReady !== true) {
      return isBreezeReady;
    }

    const isSecurityReady = await SecurityActivateGuard(
      next,
      state,
      log,
      security,
      router,
    );
    if (isSecurityReady !== true) {
      return isSecurityReady;
    }

    return true;
  } catch (error) {
    log.logException(error);
    return false;
  }
}) satisfies CanActivateFn;
