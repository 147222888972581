import * as nprogress from "nprogress";

interface NProgressSettings {
  showSpinner: boolean | undefined;
  delay: number | undefined;
}
export interface INProgress {
  configure: (settings: NProgressSettings) => void;
  doStart: () => void;
  start: { (): void; (): void };
  doDone: () => void;
  done: { (): void; (): void };
  clearDelay: () => void;

  startDelay: ReturnType<typeof setTimeout> | undefined;
  settings: NProgressSettings;
}

const NProgress = nprogress as unknown as INProgress;
NProgress.doStart = NProgress.start;
NProgress.doDone = NProgress.done;
NProgress.clearDelay = function () {
  if (this.startDelay) {
    clearTimeout(this.startDelay);
    this.startDelay = undefined;
  }
};
NProgress.start = function () {
  this.clearDelay();
  this.startDelay = setTimeout(function () {
    NProgress.doStart();
  }, this.settings.delay ?? 0);
};
NProgress.done = function () {
  this.clearDelay();
  this.doDone();
};

NProgress.configure({ showSpinner: false, delay: 250 });

export default NProgress;
