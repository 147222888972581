import Securable from "./Securable";

function getExactMatchingSecurables(
  securityRoleIds: number[],
  securableName: string,
  action: string,
  securableData: Securable[],
) {
  const exactMatches: Securable[] = [];

  for (const securable of securableData) {
    if (securable.SecurableName.toLowerCase() !== securableName.toLowerCase()) {
      continue;
    }

    if (securable.Action.toLowerCase() !== action.toLowerCase()) {
      continue;
    }

    const roleIndex = securityRoleIds.findIndex(
      (securityRoleId) => securityRoleId === securable.SecurityRoleId,
    );
    if (roleIndex === -1) {
      continue;
    }

    exactMatches.push(securable);
  }

  return exactMatches;
}

export default getExactMatchingSecurables;
