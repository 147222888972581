<ng-container *ngIf="to">
  <a
    *securable="
      securableName;
      enabled: securableEnabled;
      part: securablePart;
      action: securableAction
    "
    [title]="title ?? ''"
    [class]="classes"
    [href]="baseUrl + to + (queryParams | queryParams)"
    [routerLinkActive]="routerLinkActive"
    [routerLinkActiveOptions]="{ exact: exact }"
    (click)="onClick($event)"
  >
    <ng-container *ngTemplateOutlet="content" />
  </a>
</ng-container>
<ng-container *ngIf="href && target !== '_blank'">
  <a
    [title]="title ?? ''"
    [class]="classes"
    [href]="href"
    [target]="target ?? ''"
    [rel]="rel ?? ''"
  >
    <ng-container *ngTemplateOutlet="content" />
  </a>
</ng-container>
<ng-container *ngIf="href && target === '_blank'">
  <a
    [title]="title ?? ''"
    [class]="classes"
    [href]="href"
    [target]="target"
    [rel]="rel ?? ''"
  >
    <ng-container *ngTemplateOutlet="content" />
    <standard-icon [icon]="faUpRightFromSquare" />
  </a>
</ng-container>
<ng-container *ngIf="!to && !href">
  <button
    *securable="
      securableName;
      enabled: securableEnabled;
      part: securablePart;
      action: securableAction
    "
    [type]="type"
    [name]="name ?? ''"
    [value]="value ?? ''"
    [title]="title ?? ''"
    [class]="classes"
    (click)="onClick($event)"
    [disabled]="disabled"
  >
    <ng-container *ngTemplateOutlet="content" />
  </button>
</ng-container>

<ng-template #content>
  <standard-icon *ngIf="icon" [icon]="icon" [styleClass]="iconStyleClass" />{{
    label
  }}<ng-content />
</ng-template>
