import { NgIf, NgTemplateOutlet } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Params, Router, RouterLinkActive } from "@angular/router";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { QueryParamsPipe } from "src/standard/standard-button/query-params.pipe";
import {
  IconDefinition,
  StandardIconComponent,
} from "src/standard/standard-icon/standard-icon.component";
import {
  SecurableAction,
  SecurableName,
} from "../security/securable.directive";
import { SecurityModule } from "../security/security.module";

type ThemeVariants =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "danger"
  | "accent"
  | "tertiary";

export type StandardButtonVariant =
  | "unstyled"
  | ThemeVariants
  | "link"
  | `${ThemeVariants}-link`
  | "outline"
  | `${ThemeVariants}-outline`
  | "subtle"
  | `${ThemeVariants}-subtle`;
export const DefaultStandardButtonVariant = "primary";

@Component({
  standalone: true,
  selector: "standard-button",
  templateUrl: "./standard-button.component.html",
  styles: [
    `
      :host {
        display: inline-flex;
      }

      .icon-button {
        display: inline-flex;
        gap: 0.375rem;
        align-items: center;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      // For icon-button with a single icon and nothing else,
      // we want it to show as a perfect square/circle
      :host ::ng-deep .rounded-circle {
        // Reset padding to be consistent
        --bs-btn-padding-x: 9px;
        --bs-btn-padding-y: 9px;

        // Center icon
        justify-content: center;

        // Make the width/height full button 1:1 ratio
        width: var(--bs-h-btn);
        height: var(--bs-h-btn);
      }
    `,
  ],
  imports: [
    NgIf,
    NgTemplateOutlet,
    RouterLinkActive,

    StandardIconComponent,

    SecurityModule,
    QueryParamsPipe,
  ],
})
export class StandardButtonComponent implements OnInit {
  faUpRightFromSquare = faUpRightFromSquare;
  baseUrl: string = "";

  constructor(private router: Router) {
    this._handleClick = this._handleClick.bind(this);
  }

  _disabled: boolean | undefined;
  @Input() set disabled(newVal: boolean | undefined) {
    this._disabled = newVal;

    this.updateClasses();
  }
  get disabled() {
    return this._disabled;
  }

  @Input() type: "button" | "submit" | "reset" = "button";
  @Input() routerLinkActive: string = "active";
  @Input() exact: boolean = true;

  _styleClass: string | undefined;
  @Input() set styleClass(newVal: string | undefined) {
    this._styleClass = newVal;

    this.updateClasses();
  }
  get styleClass() {
    return this._styleClass;
  }

  @Input() label: string | undefined;

  @Input() title: string | undefined;
  @HostBinding("attr.title") __title = ""; // Remove browser title/tooltip

  @Input() icon: IconDefinition | undefined;
  @Input() iconStyleClass: string | undefined;
  @Input() target: string | undefined;
  @Input() rel: string | undefined = "noreferrer noopener";

  @Input() name: string | undefined;
  @Input() value: string | undefined;

  @Input() securableEnabled: boolean = true;
  @Input() securableName: SecurableName | undefined;
  @Input() securablePart: SecurableName | undefined;
  @Input() securableAction: SecurableAction | undefined;

  _variant: StandardButtonVariant = DefaultStandardButtonVariant;
  @Input() set variant(newVal: StandardButtonVariant) {
    this._variant = newVal;

    this.updateClasses();
  }
  get variant() {
    return this._variant;
  }

  @Input() href: string | undefined;
  @Input() to: string | undefined;
  @Input() command:
    | (() => void | Promise<void> | boolean | Promise<boolean>)
    | undefined;
  @Input() queryParams: Params | undefined;
  @Output() handleClick = new EventEmitter<MouseEvent>();

  classes: string = "";

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    const newClasses: string[] = [];

    if (this.variant === "unstyled") {
      // don't add own styles
    } else {
      const isLink = this.variant === "link" || this.variant.endsWith("-link");
      if (isLink) {
        // Style w/ bootstrap
        newClasses.push("icon-link");
      } else {
        newClasses.push("icon-button");
      }

      if (this.variant === "outline" || this.variant.endsWith("-outline")) {
        newClasses.push("btn");
        if (this.variant.endsWith("-outline")) {
          const parts = this.variant.split("-");
          const subVariant = parts.slice(0, -1).join("-");
          newClasses.push("btn-outline-" + subVariant);
        } else {
          newClasses.push("btn-outline-primary");
        }
      } else if (
        this.variant === "subtle" ||
        this.variant.endsWith("-subtle")
      ) {
        newClasses.push("btn");
        if (this.variant.endsWith("-subtle")) {
          const parts = this.variant.split("-");
          const subVariant = parts.slice(0, -1).join("-");
          newClasses.push("btn-outline-" + subVariant);
        } else {
          newClasses.push("btn-outline-primary");
        }

        newClasses.push("fw-bold border-0");
      } else if (isLink) {
        newClasses.push("btn btn-link");
        if (this.variant.endsWith("-link")) {
          const parts = this.variant.split("-");
          const subVariant = parts.slice(0, -1).join("-");
          newClasses.push("link-" + subVariant);
        }
      } else {
        if (this.variant === "primary" || !this.disabled) {
          newClasses.push("btn btn-" + this.variant);
        } else {
          newClasses.push("btn btn-secondary");
        }
      }
    }

    if (this.disabled) {
      newClasses.push("disabled");
    }

    if (this.styleClass) {
      newClasses.push(this.styleClass);
    }

    this.classes = newClasses.join(" ");

    // Since we're manually handling button clicks for links,
    // make sure the link is accurate
    if (this.to) {
      if (typeof this.to === "string" && this.to.startsWith("/")) {
        this.baseUrl = "";
      } else {
        this.baseUrl = window.location.pathname + "/";
      }
    }
  }

  private async _handleClick(e: MouseEvent) {
    if (this.command) {
      const allowPropagation = await this.command();
      if (allowPropagation === false) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
    }

    if (this.to) {
      e.preventDefault();
      e.stopPropagation();

      // Allow internal "to" items to be opened in new tab
      if (this.target) {
        window.open(this.baseUrl + this.to, this.target);
        return;
      }

      const navRes = await this.router.navigate([this.baseUrl + this.to], {
        queryParams: this.queryParams,
      });
      if (!navRes) {
        return;
      }
    }
  }

  private _getHandleClick(
    e: MouseEvent,
  ): ((e: MouseEvent) => Promise<void>) | undefined {
    if (!e.altKey && !e.metaKey && !e.ctrlKey && !e.shiftKey) {
      if (this.command || this.to) {
        return this._handleClick;
      }
    }

    return undefined;
  }

  // this is not async because html won't respect us
  onClick(e: MouseEvent) {
    const handleClick = this._getHandleClick(e);
    if (handleClick) {
      void handleClick(e);

      return false;
    } else {
      this.handleClick.emit(e);
      return true;
    }
  }
}
