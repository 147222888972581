<standard-button
  *ngIf="expanded !== undefined && widthCategory !== undefined"
  [securableEnabled]="false"
  type="button"
  variant="secondary-link"
  [styleClass]="styleClass || ''"
  [disabled]="disabled"
  (handleClick)="onToggle.emit(!expanded)"
  [pTooltip]="expanded ? 'Collapse' : 'Expand'"
  [tooltipPosition]="tooltipPosition ?? 'right'"
  ><ng-container *ngIf="widthCategory > SM"><app-icon-grid /></ng-container
  ><ng-container *ngIf="widthCategory <= SM"
    ><div class="d-flex align-items-center">
      <div
        style="width: 10px; height: 10px; margin-right: -3px"
        class="d-flex align-items-center justify-content-center overflow-hidden"
      >
        <standard-icon
          [@leftArrow]="{
            value: expanded ? 'expanded' : 'collapsed'
          }"
          styleClass="overflow-hidden fs-arrow"
          [icon]="faCaretLeft"
          style="margin-left: 5px"
        />
      </div>
      <standard-icon [icon]="faBars" styleClass="fs-3" />
      <div
        style="width: 10px; height: 10px; margin-left: -3px"
        class="d-flex align-items-center justify-content-center overflow-hidden"
      >
        <standard-icon
          [@rightArrow]="{
            value: !expanded ? 'expanded' : 'collapsed'
          }"
          styleClass="overflow-hidden fs-arrow"
          [icon]="faCaretRight"
          style="margin-left: -5px"
        />
      </div></div></ng-container
></standard-button>
