import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { EntityManagerProviderService } from "../../services//entity-manager-provider/entity-manager-provider.service";
import { ServerSideConfigDataService } from "../../services/server-side-config-data/server-side-config-data.service";
import { UnitOfWorkService } from "../../services/unit-of-work/unit-of-work.service";
import { RequireAuthenticatedUserGuard } from "../auth/require-authenticated-user.guard";
import { getResolvedUrl } from "src/standard/security/security-activate.guard";

@Injectable({
  providedIn: "root",
})
export class BreezeSetupGuard implements CanActivate {
  constructor(
    private serverSide: ServerSideConfigDataService,
    private entityManagerProvider: EntityManagerProviderService,
    private uowService: UnitOfWorkService,
    private authGuard: RequireAuthenticatedUserGuard,
    private router: Router,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const url = getResolvedUrl(next);

    const isAuth = await this.authGuard.canActivate(next, state);
    if (!isAuth) {
      return Promise.resolve(false);
    }

    return this.serverSide
      .loadServerSideData()
      .then(() => this.entityManagerProvider.init())
      .then(() => {
        this.uowService.init();
        return true;
      })
      .catch(async (error: unknown) => {
        if (
          error &&
          typeof error === "object" &&
          "status" in error &&
          typeof error.status === "number"
        ) {
          switch (error.status) {
            case 401:
            case 403:
              if (url !== "/access-denied") {
                await this.router.navigate(["/access-denied"]);
                return true;
              }
              break;

            default:
              if (url !== "/maintenance") {
                await this.router.navigate(["/maintenance"]);
                return true;
              }
          }
        }

        return false;
      });
  }
}
