import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AjaxAdapter,
  AjaxConfig,
  EntityQuery,
  HttpResponse,
} from "breeze-client";
import { AjaxHttpClientAdapter } from "breeze-client/adapter-ajax-httpclient";
import invariant from "tiny-invariant";

export function customAjaxAdapterFactory(http: HttpClient): CustomAjaxAdapter {
  return new CustomAjaxAdapter(http);
}

@Injectable({
  providedIn: "root",
})
export class CustomAjaxAdapter
  extends AjaxHttpClientAdapter
  implements AjaxAdapter
{
  name = "customAjaxAdapter";
  defaultSettings = {};

  constructor(public http: HttpClient) {
    super(http);

    invariant(http, "HttpClient is not provided via DI for some reason");
  }

  initialize() {}

  async ajax(config: AjaxConfig) {
    // If it's a GET, just call the base implementation
    if (config.type === "GET") {
      return super.ajax(config);
    }

    // If we're posting to a URL that ends with savechanges, just call the base implementation
    if (config.url && config.url.endsWith("savechanges")) {
      return super.ajax(config);
    }

    // Check if it's a POST request and extract the JSON object from config.url to be used as the request body
    if ((config.type === "POST" || config.type === "POST-BODY") && config.url) {
      const url = new URL(config.url);

      // url decoded query string without the leading ? and replacing \" with "
      const queryString = decodeURIComponent(url.search.slice(1)).replace(
        /\\"/g,
        '"',
      );

      const params = queryString
        ? (JSON.parse(queryString) as Record<string, unknown>)
        : {};
      const nonQueryStringUrl = url.origin + url.pathname;

      config.url = nonQueryStringUrl;

      const existingData = !config.data
        ? {}
        : typeof config.data === "string" &&
          config.data.startsWith("{") &&
          config.data.endsWith("}")
        ? (JSON.parse(config.data) as Record<string, unknown>)
        : typeof config.data === "object"
        ? (config.data as Record<string, unknown>)
        : {};
      config.data = { ...existingData, ...params };
    }

    // Set the `Content-Type` header if it's not set
    const configHeaders: { [name: string]: string } = config.headers ?? {};
    if (!configHeaders["Content-Type"]) {
      configHeaders["Content-Type"] = "application/json";
    }

    // Set headers
    const headers = new HttpHeaders(configHeaders);

    const {
      where,
      noTracking,
      skip,
      orderBy,
      take,
      top,
      inlineCount,
      ...rest
    } = config.data as EntityQuery;
    let bodyData: Partial<EntityQuery> = {
      where,
      noTracking,
      skip,
      orderBy,
      take,
      top,
      inlineCount,
    };

    // loop over fields in rest, adding to params, and coalescing nulls and undefined to empty string
    const params: {
      [param: string]:
        | string
        | number
        | boolean
        | readonly (string | number | boolean)[];
    } = {};
    for (const [key, val] of Object.entries(rest) as [
      key: string,
      val: unknown,
    ][]) {
      if (val === null || val === undefined) {
        // skip
      } else {
        if (typeof val === "string" || typeof val === "number" || typeof val === "boolean") {
          params[key] = val;
        } else if (Array.isArray(val)) {
          if (val.length > 0) {
            const valFirst = val[0];
            if (typeof valFirst === "string") {
              params[key] = val as string[];
            } else if (typeof valFirst === "number") { 
              params[key] = val as number[];
            } else if (typeof valFirst === "boolean") {
              params[key] = val as boolean[];
            } else {
              // Array of objects
              if (config.type === "POST-BODY") {
                // eslint-disable-next-line
                params[key] = val as any;
              } else {
                params[key] = JSON.stringify(val);
              }
            }
          }
        } else {
          if (config.type === "POST-BODY") {
            // eslint-disable-next-line
            params[key] = val as any;
          } else {
            params[key] = JSON.stringify(val);
          }
        }
      }
    }

    if (config.type === "POST-BODY") {
      bodyData = { ...bodyData, ...params };
    }

    // Call the `HttpClient` with the updated config
    const methodType = config.type === "POST-BODY" ? "POST" : config.type;
    return this.http
      .request(methodType!, config.url, {
        params: config.type === "POST-BODY" ? undefined : params,
        body: bodyData,
        headers: headers,
        observe: "response",
        responseType: "json",
        withCredentials: config.crossDomain === true,
      })
      .toPromise()
      .then((response) => {
        // if the response was cancelled, ignore
        if (!response?.status) {
          return;
        }

        invariant(response);
        // Convert the Angular response to the Breeze response format
        const httpResponse: HttpResponse = {
          config: config,
          data: response.body,
          getHeaders: (key: string) => response.headers.get(key) ?? "",
          status: response.status,
        };
        config.success(httpResponse);
      });
  }
}
