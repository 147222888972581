import trimSpecific from "../utils/trimSpecific";
import getExplicitSecurableName, {
  ExplicitSecurableElementProps,
  isExplicitSecurableElement,
} from "./securables/getExplicitSecurableName";
import getRouteSecurableName, {
  isSecurableRoute,
  SecurableRouteProps,
} from "./securables/getRouteSecurableName";

export type GetSecurableNameProps =
  | SecurableRouteProps
  | ExplicitSecurableElementProps;

function getSecurableName(props: GetSecurableNameProps) {
  let securableName: string | undefined;
  if (isExplicitSecurableElement(props)) {
    securableName = getExplicitSecurableName(props);
  }

  if (!securableName && isSecurableRoute(props)) {
    securableName = getRouteSecurableName(props);
  }

  if (securableName) {
    // Convert any slashes to dots
    securableName = securableName.replaceAll("/", ".");

    // Remove any part after the # since that's not relevant
    if (securableName.indexOf("#") >= 0) {
      const lastIndex = securableName.lastIndexOf("#");
      securableName = securableName.slice(0, lastIndex);
    }

    // Remove any part after the ? since that's a query param
    if (securableName.indexOf("?") >= 0) {
      const lastIndex = securableName.lastIndexOf("?");
      securableName = securableName.slice(0, lastIndex);
    }

    // Remove .cancel since there's no special security on that
    if (securableName.endsWith(".cancel")) {
      securableName = securableName.slice(0, securableName.lastIndexOf("."));
    }

    // Coalesce '.save-and-exit' to '.save'
    if (securableName.endsWith(".save-and-exit")) {
      securableName =
        securableName.slice(0, securableName.lastIndexOf(".")) + ".save";
    }

    // Coalesce '.delete-selected' to '.delete'
    if (securableName.endsWith(".delete-selected")) {
      securableName =
        securableName.slice(0, securableName.lastIndexOf(".")) + ".delete";
    }

    // Convert any slashes to dots and remove leading/trailing dots
    if (securableName.indexOf(".") >= 0) {
      securableName = trimSpecific(securableName, ".");
    }

    return securableName;
  }

  throw new Error("Cannot determine an appropriate securable name.");
}

export default getSecurableName;
