function getParentSecurableName(securable: string): string | undefined {
  if (typeof securable === "string" && securable !== "") {
    const lastDotIndex = securable.lastIndexOf(".");
    if (lastDotIndex > -1) {
      return securable.substring(0, lastDotIndex);
    }

    return "";
  }

  return undefined;
}

export default getParentSecurableName;
