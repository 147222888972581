import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import IStandardLogService from "./IStandardLogService";
import { getApiFieldErrors } from "src/standard/ts/formUtils";

@Injectable()
export class StandardLogService implements IStandardLogService {
  private _appInsights: ApplicationInsights | undefined;

  get appInsights() {
    if (!this._appInsights) {
      this._appInsights = this.initializeAppInsights();
      this._appInsights.loadAppInsights();
    }

    return this._appInsights;
  }

  initializeAppInsights(userId?: string) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window.__settings?.appInsightsKey,
        accountId: userId,
        enableDebug: true,
        enableUnhandledPromiseRejectionTracking: true,

        // we disable this on purpose since route-level logging is handled
        // manually via router events in app.component.ts
        enableAutoRouteTracking: false,
      },
    });

    return appInsights;
  }

  //Need to completely reinitialized appInsights to get the change to take effect
  ensureUserIdIsSet(userId?: string) {
    if (this.appInsights.config.accountId != userId) {
      this._appInsights = this.initializeAppInsights(userId);
      this._appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    try {
      // option to call manually
      this.appInsights.trackPageView({
        name: name,
        uri: url,
      });
    } catch (error) {
      // ignore in case of ad blockers etc
    }
  }

  logEvent(name: string, properties?: { [key: string]: unknown }) {
    try {
      this.appInsights.trackEvent({ name: name }, properties);
    } catch (error) {
      // ignore in case of ad blockers etc
    }
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: unknown },
  ) {
    try {
      this.appInsights.trackMetric(
        { name: name, average: average },
        properties,
      );
    } catch (error) {
      // ignore in case of ad blockers etc
    }
  }

  logException(exception: unknown) {
    const severityLevel = 3;

    const fieldErrors = getApiFieldErrors(exception);
    console.error({ exception, fieldErrors });

    try {
      this.appInsights.trackException({
        exception: exception as Error,
        severityLevel: severityLevel,
      });
    } catch (error) {
      // ignore in case of ad blockers etc
    }
  }

  logExceptionFromInterceptor(exception: HttpErrorResponse) {
    this.logException(new Error(exception.message + " | " + exception.error));
  }

  logTrace(message: string, properties?: { [key: string]: unknown }) {
    try {
      this.appInsights.trackTrace({ message: message }, properties);
    } catch (error) {
      // ignore in case of ad blockers etc
    }
  }
}
