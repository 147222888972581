/* eslint-disable @typescript-eslint/no-non-null-assertion */

function segmentizeUri(uri: string) {
  return (
    uri
      // strip starting/ending slashes
      .replace(/(^\/+|\/+$)/g, "")
      .split("/")
  );
}

export interface SecurableRouteProps {
  path: string;
  uri: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSecurableRoute(props?: any): props is SecurableRouteProps {
  return (
    typeof props === "object" &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof props.path === "string" &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof props.uri === "string"
  );
}

function getRouteSecurableName({ path, uri }: SecurableRouteProps) {
  const securableParts: string[] = [];

  const pathSegments = segmentizeUri(path);
  const uriSegments = segmentizeUri(uri);
  for (let i = 0; i < pathSegments.length; i++) {
    const pathSegment = decodeURIComponent(pathSegments[i]!);
    const uriSegment = decodeURIComponent(uriSegments[i]!);

    const wildcardMatches = pathSegment.match(/^[*]$/);
    if (wildcardMatches) {
      securableParts.push("*");
      continue;
    }

    const namedParamMatches = pathSegment.match(/^[:](\w+)\(([\w|]+)\)/);
    if (namedParamMatches) {
      const namedParams = namedParamMatches[2]!;
      const namedParamsList = namedParams.split("|");
      const matchingParamName = namedParamsList.find(
        (param) => param === uriSegment,
      );
      if (matchingParamName) {
        securableParts.push(matchingParamName);
        continue;
      }
    }

    const namedWildcardMatches = pathSegment.match(/^[:](\w+)/);
    if (namedWildcardMatches) {
      securableParts.push(namedWildcardMatches[1]!);
      continue;
    }

    securableParts.push(pathSegment);
  }

  return securableParts.join(".").toLowerCase();
}

export default getRouteSecurableName;
