import { Pipe, PipeTransform } from "@angular/core";

export function updateOrdersInPlace<
  TData extends { order: number | undefined },
>(items: TData[] | null | undefined): void {
  if (!items) {
    return;
  }

  if (items.length > 0) {
    let nextOrder = 1;

    // Load orders from props, and populate orders for all
    items.forEach((x, i) => {
      if (x.order === undefined) {
        while (items.find((y) => y.order === nextOrder)) {
          nextOrder++;
        }

        x.order = nextOrder;
      }
    });

    // Sort by order
    items.sort((a, b) => {
      const diff = a.order! - b.order!;
      return diff;
    });
  }
}

export function sortByOrder<TData extends { order?: number | undefined }>(
  input: TData[] | null | undefined,
) {
  const items = [...(input ?? [])];

  // Sort by order
  items.sort((a, b) => {
    const diff = a.order! - b.order!;
    return diff;
  });

  return items;
}

@Pipe({
  standalone: true,
  name: "standardDatatableSortByOrder",
})
export class StandardDatatableSortByOrderPipe<
  TData extends { order: number | undefined },
> implements PipeTransform
{
  transform(
    input: TData[] | null | undefined,
    retriggerKey?: unknown,
  ): TData[] {
    const items = sortByOrder(input);

    return items;
  }
}
