<standard-nav-shell
  *ngIf="!initializing"
  class="d-flex flex-column min-vh-100 bg-body-tertiary"
  [topStyleClass]="'fixed-top shadow-sm'"
  [sideOverlayFullMaxSize]="99999"
  [defaultSideCollapsedMaxSize]="99999"
  [autoHideSideNavCutoffMaxSize]="MD"
  [autoShowSideNavCutoffMinSize]="0"
>
  <ng-template #navTop let-registerNavTemplates="registerNavTemplates">
    <standard-lazy-component
      *ngIf="registerNavTemplates"
      [getContent]="getNavContent"
      [lazyProps]="{
        registerNavTemplates: registerNavTemplates
      }"
    >
      <ng-template #skeleton>
        <nav
          class="navbar navbar-expand navbar-light bg-body border-bottom"
          style="height: var(--bs-h-header)"
        ></nav>
      </ng-template>
    </standard-lazy-component>
  </ng-template>

  <div style="margin-top: var(--bs-h-header)">
    <router-outlet />

    <div id="loading-bar-dots" class="loading-bar-dots loading-data">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</standard-nav-shell>
