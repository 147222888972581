/* eslint-disable */
import { Validator } from "breeze-client";
import { ValidationFn } from "breeze-client/src/validate";

type Context = Record<string, any>;

interface IValidatorMeta {
  displayName: string;
  requiredErrorMessage?: string;
  range?: {
    min: number;
    max: number;
    errorMessage?: string;
  };
  regEx?: {
    expression: string;
    errorMessage?: string;
  };
  myType?: string & {
    errorMessage: string;
  };
}

export default class DefaultBreezeValidators {
  private static removeValidator(validators: any[], name: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const index = validators.findIndex((x) => x.name === name);
    if (index > -1) {
      validators.splice(index, 1);
    }
  }

  private static validator(
    context: Context,
    messageTemplate: string,
    name: string,
    fn: ValidationFn,
  ) {
    const defaultContext = { messageTemplate: messageTemplate, ...context };

    return new Validator(name, fn, defaultContext);
  }

  private static int16Validator(v: unknown, ctxt: Context) {
    return this.intValidator(v, ctxt, -32768, 32767);
  }

  private static int32Validator(v: unknown, ctxt: Context) {
    return this.intValidator(v, ctxt, -2147483648, 2147483647);
  }

  static int64Validator(v: unknown, ctxt: Context) {
    // eslint-disable-next-line no-loss-of-precision
    return this.intValidator(
      v,
      ctxt,
      -9223372036854775808,
      9223372036854775807,
    );
  }

  private static intValidator(
    v: unknown,
    ctxt: Context,
    minValue: number,
    maxValue: number,
  ) {
    if (v == null || v === "") {
      return true;
    } // if empty string then required will validate empty string
    if (typeof v === "string") {
      v = parseInt(v, 0);
    }
    if (typeof v === "number" && !isNaN(v) && Math.floor(v) === v) {
      if (minValue != null && v < minValue) {
        return false;
      }
      if (maxValue != null && v > maxValue) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  private static numValidator(v: unknown, ctxt: Context) {
    if (v == null || v === "") {
      return true;
    } // if empty string then required will validate empty string
    if (typeof v === "string") {
      v = parseFloat(v);
    }
    if (typeof v === "number" && !isNaN(v)) {
      return true;
    } else {
      return false;
    }
  }

  private static requiredValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% is required",
      "required",
      (value, ctxt) => {
        if ((value !== 0 && !value) || value == null) {
          return false;
        } // '== null' matches null and empty string

        return true;
      },
    );
  }

  private static rangeValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% must be between " + context.min + " and " + context.max,
      "range_" + context.min + "_and_" + context.max,
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        if (value < context.min || value > context.max) {
          return false;
        }

        return true;
      },
    );
  }

  private static regexValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% is not in the correct format",
      "regex_" + context.name,
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        const patt = new RegExp(context.regex, "igm");
        return patt.test(value);
      },
    );
  }

  private static currencyValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% must be entered as currency",
      "currency",
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        // Allows for US and International currency formats
        return new RegExp(
          /(?:^\d{1,3}(?:\.?\d{3})*(?:,\d{2})?$)|(?:^\d{1,3}(?:,?\d{3})*(?:\.\d{2})?$)/,
        ).test(value);
      },
    );
  }

  private static emailValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% must be a valid e-mail address",
      "email",
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        return new RegExp(
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
        ).test(value);
      },
    );
  }

  private static urlValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% must be a valid URL",
      "url",
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        return new RegExp(
          /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
        ).test(value);
      },
    );
  }

  private static creditCardValidator(context: Context) {
    return this.validator(
      context,
      "%displayName% is not a valid credit card number",
      "creditCard",
      (value, ctxt) => {
        if (!value || value == null) {
          return true;
        }

        const cc = value.replace(/[^\d]/g, "");

        if (this.luhn(cc)) {
          return new RegExp(
            /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
          ).test(cc);
        }

        return false;
      },
    );
  }

  private static luhn(cc: string) {
    let sum = 0;
    let i: number = 0;

    for (i = cc.length - 2; i >= 0; i -= 2) {
      const char = cc.charAt(i);
      sum += [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][parseInt(char, 10)] ?? 0;
    }
    for (i = cc.length - 1; i >= 0; i -= 2) {
      sum += parseInt(cc.charAt(i), 10);
    }
    return sum % 10 === 0;
  }

  // These validators are configured on the server side via EF
  public static setupEFValidators(
    validators: Validator[],
    meta: IValidatorMeta,
  ) {
    for (const validator of validators) {
      switch (validator.name) {
        case "int16":
          (validator as any).valFn = this.int16Validator.bind(this);
          break;
        case "int32":
          (validator as any).valFn = this.int32Validator.bind(this);
          break;
        case "int64":
          (validator as any).valFn = this.int64Validator.bind(this);
          break;
        case "number":
          (validator as any).valFn = this.numValidator.bind(this);
      }
    }

    if (meta.requiredErrorMessage) {
      this.removeValidator(validators, "required");
      validators.push(
        this.requiredValidator({ messageTemplate: meta.requiredErrorMessage }),
      );
    }

    if (meta.range) {
      this.removeValidator(validators, "range");
      validators.push(
        this.rangeValidator({
          min: meta.range.min,
          max: meta.range.max,
          messageTemplate: meta.range.errorMessage,
        }),
      );
    }

    if (meta.regEx) {
      validators.push(
        this.regexValidator({
          regex: meta.regEx.expression,
          name: meta.displayName,
          messageTemplate: meta.regEx.errorMessage,
        }),
      );
    }

    if (meta.myType === "EmailAddress") {
      validators.push(
        this.emailValidator({ messageTemplate: meta.myType.errorMessage }),
      );
    }

    if (meta.myType === "Currency") {
      validators.push(
        this.currencyValidator({ messageTemplate: meta.myType.errorMessage }),
      );
    }

    if (meta.myType === "Url" || meta.myType === "ImageUrl") {
      validators.push(
        this.urlValidator({ messageTemplate: meta.myType.errorMessage }),
      );
    }

    if (meta.myType === "Currency") {
      validators.push(
        this.creditCardValidator({ messageTemplate: meta.myType.errorMessage }),
      );
    }
  }
}
