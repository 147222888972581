/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Entity, EntityChangedEventArgs, SaveOptions } from "breeze-client";
import { matchSorter } from "match-sorter";
import { BehaviorSubject, Subject } from "rxjs";
import { BridgeService } from "src/app/services/bridge/bridge.service";
import { EntityManagerProviderService } from "src/app/services/entity-manager-provider/entity-manager-provider.service";
import { GlobalErrorHandler } from "src/app/services/global-error-handler";
import { filesApi } from "src/config";
import invariant from "tiny-invariant";
import {
  BreezeOptions,
  BreezeResult,
  QueryOptions,
  dlv,
  inlineCountResult,
  instanceOfInlineCountQueryOptions,
  isAdblockEnabled,
} from "../utilities";
import { MetaEntity } from "src/model/metaEntity";
import { Repository } from "../repository";
// import * as modelEnums from "src/model/modelEnums";

type BreezeFilterKnownOpKey = "contains" | string;

export interface SurveyResponseDto {
  SurveyQuestionId: number;
  ResponseVal: string | null | undefined;
}
interface BreezeFilterOpObj {
  key: BreezeFilterKnownOpKey;
}

interface BreezeFilterObj {
  expr1Source: string;
  op: BreezeFilterOpObj;
  expr2Source: string;
}

export type KeysOfType<T, TProp> = {
  [P in keyof T]: T[P] extends TProp ? P : never;
}[keyof T];
export type RepositoryNames<T extends MetaEntity = MetaEntity> = KeysOfType<
  BridgeService,
  Repository<T>
>;

export function isTrackedBreezeEntity(
  entity: MetaEntity | { [x: string]: unknown },
) {
  if (!entity || !entity.entityAspect) {
    return false;
  }

  return true;
}

export function assertCanDelete(entity: MetaEntity) {
  if (!isTrackedBreezeEntity(entity)) {
    throw Error(
      "Cannot delete this entity -- did you mistakenly have noTracking: true? noTracking must be false to delete!",
    );
  }
}

function paginate<T>(array: T[], pageSize: number, pageNumber: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

export async function getStaticResults<
  T,
  O extends BreezeOptions = QueryOptions,
>(
  results: T[] | undefined,
  options?: O,
  fetchMeta?: unknown,
): Promise<BreezeResult<O, T>> {
  const data = {
    results: results ?? [],
    inlineCount: results?.length ?? 0,
  };

  // Apply client-side filters for known typed stuff
  if (options?.filter) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter = options.filter as any as BreezeFilterObj;
    if (filter.op.key === "contains") {
      // This uses match-sorter. Docs here:
      // https://github.com/kentcdodds/match-sorter
      // eslint-disable-next-line
      data.results = matchSorter(data.results, filter.expr2Source, {
        keys: [filter.expr1Source],
      });
    }
  }

  // Allow client-side sorting
  if (options?.sort) {
    const sortFields = options.sort.split(",").map((x) => x.trim());
    data.results.sort((a, b) => {
      for (const sortField of sortFields) {
        const splitz = sortField.split(" ");
        const [fieldName, direction = "asc"] = splitz;

        let diff = 0;
        if (typeof a === "object") {
          // eslint-disable-next-line
          a = dlv(a as any, fieldName);
        }

        if (typeof b === "object") {
          // eslint-disable-next-line
          b = dlv(b as any, fieldName);
        }

        if (typeof a === "string" && typeof b === "string") {
          diff = a.localeCompare(b);
        } else {
          diff = a < b ? -1 : a > b ? 1 : 0;
        }

        if (diff !== 0) {
          if (
            direction.toLowerCase() === "desc" ||
            direction.toLowerCase() === "descending"
          ) {
            return diff * -1;
          } else {
            return diff;
          }
        }
      }

      return 0;
    });
  }

  // Allow client-side paging
  if (options?.take) {
    data.results = paginate(
      data.results,
      options.take,
      (options.skip ?? 0) / options.take + 1,
    );
  }

  const inlineCount = instanceOfInlineCountQueryOptions(options)
    ? options.inlineCount
    : false;

  if (inlineCount === true) {
    // eslint-disable-next-line
    return new inlineCountResult(
      // eslint-disable-next-line
      <T[]>(<any>data.results),
      data.inlineCount,
      // eslint-disable-next-line
    ) as any;
  }

  // eslint-disable-next-line
  return (<T[]>(<any>data.results)) as any;
}
@Injectable({
  providedIn: "root",
})
export class UnitOfWorkService implements OnDestroy {
  hasChanges = new BehaviorSubject(false);
  entityChanged = new Subject<EntityChangedEventArgs>();

  private initialized = false;

  constructor(
    private http: HttpClient,
    public bridge: BridgeService,
    private provider: EntityManagerProviderService,
    private errorHandler: GlobalErrorHandler,
  ) { }

  init() {
    this.bridge.init();

    if (this.initialized) {
      return;
    }

    this.provider.manager().hasChangesChanged.subscribe((evt) => {
      this.hasChanges.next(evt.hasChanges);
    });

    this.provider.manager().entityChanged.subscribe((evt) => {
      this.entityChanged.next(evt);
    });

    this.initialized = true;
  }

  async commit(entities?: Entity[] | null) {
    const saveOptions = new SaveOptions({ resourceName: "savechanges" });
    return this.provider.manager().saveChanges(entities, saveOptions);
  }

  hasPendingChanges(): boolean {
    return this.provider.manager().hasChanges();
  }

  rollback() {
    try {
      this.provider.manager().rejectChanges();
    } catch (error) {
      // ignore
    }
  }

  async deleteEntity<T extends MetaEntity = MetaEntity>(
    entity: T | null | undefined,
    uowRepositoryName: RepositoryNames<T>,
  ) {
    if (entity) {
      assertCanDelete(entity);

      entity.isDeleted = true;

      const repository = this.bridge[uowRepositoryName];
      const promise = repository
        ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        repository.saveChanges([entity as any])
        : this.commit();

      return promise.catch((error) => {
        console.error(error);
        entity.isDeleted = false;

        throw error;
      });
    }
  }

  ngOnDestroy() {
    this.hasChanges.unsubscribe();
    this.entityChanged.unsubscribe();
  }

  private isEmpty(obj: Record<string, unknown>): boolean {
    for (const prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return true;
  }

  private buildUrl(route: string) {
    let validRoute = route;
    if (route.substring(0, 1) === "/") {
      validRoute = route.slice(1, route.length);
    }
    return `${filesApi}/${validRoute}`;
  }

  private async httpGet(
    url: string,
    params: // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any> | undefined = undefined,
    responseType: "arraybuffer" | "json" = "arraybuffer",
  ) {
    let absoluteUrl = this.buildUrl(url);

    // Include a shallow version of the params
    // If you need anything more complex, switch to using httpPost
    if (params != undefined && !this.isEmpty(params)) {
      const keys = Object.keys(params);
      const shallowQueryString = keys
        .filter((k) => params[k])
        .map((k) => {
          // eslint-disable-next-line
          let value = params[k];
          if (value != undefined) {
            if (Array.isArray(value)) {
              return value
                .map((innerValue) => {
                  return `${encodeURIComponent(k)}=${encodeURIComponent(
                    // eslint-disable-next-line
                    innerValue as any,
                  )}`;
                })
                .join("&");
            } else {
              // eslint-disable-next-line
              return `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
            }
          }
          return "";
        })
        .join("&");
      if (absoluteUrl.includes("?")) {
        absoluteUrl += "&";
      } else {
        absoluteUrl += "?";
      }

      absoluteUrl += shallowQueryString;
    }

    if (responseType == "arraybuffer") {
      return await this.http
        .get(absoluteUrl, {
          responseType: "arraybuffer",
        })
        .toPromise();
    } else {
      return await this.http
        .get(absoluteUrl, {
          responseType: "json",
        })
        .toPromise();
    }
  }

  private httpPost<TResponse>(
    url: string,
    params: Record<string, unknown>,
  ): Promise<TResponse> {
    let absoluteUrl = this.buildUrl(url);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "_application/json");
    return this.http
      .post<TResponse>(absoluteUrl, params, { headers })
      .toPromise()
      .then((result) => {
        invariant(result !== undefined);
        return result;
      });
  }

  getAllTaskStatuses<O extends BreezeOptions = QueryOptions>(
    options?: O,
    fetchMeta?: unknown,
  ) {
    const results = [
      { label: "On Track", value: "On Track" },
      { label: "Off Track", value: "Off Track"},
      { label: "At Risk", value: "At Risk" },
      { label: "Complete", value: "Complete" },
    ];

    return getStaticResults(results, options);
  }

    

  getAllTaskProgresses<O extends BreezeOptions = QueryOptions>(
    options?: O,
    fetchMeta?: unknown,
  ) {
      const results = [
      { label: "Not Started", value: "Not Started" },
      { label: "With Preparers", value: "With Preparers" },
      { label: "With Detailed Reviewers", value: "With Detailed Reviewers" },
      { label: "With Other Reviewers", value: "With Other Reviewers" },
      { label: "With Engagement Partner", value: "With Engagement Partner" },
      { label: "With EQR", value: "With EQR" },
      { label: "Complete", value: "Complete" },
    ];

    return getStaticResults(results, options);
  }

  getAllStates<O extends BreezeOptions = QueryOptions>(
    options?: O,
    fetchMeta?: unknown,
  ) {
    const results = [
      // USA
      { label: "Alabama", code: "AL" },
      { label: "Alaska", code: "AK" },
      { label: "Arizona", code: "AZ" },
      { label: "Arkansas", code: "AR" },
      { label: "California", code: "CA" },
      { label: "Colorado", code: "CO" },
      { label: "Connecticut", code: "CT" },
      { label: "Delaware", code: "DE" },
      { label: "District of Columbia", code: "DC" },
      { label: "Florida", code: "FL" },
      { label: "Georgia", code: "GA" },
      { label: "Hawaii", code: "HI" },
      { label: "Idaho", code: "ID" },
      { label: "Illinois", code: "IL" },
      { label: "Indiana", code: "IN" },
      { label: "Iowa", code: "IA" },
      { label: "Kansa", code: "KS" },
      { label: "Kentucky", code: "KY" },
      { label: "Lousiana", code: "LA" },
      { label: "Maine", code: "ME" },
      { label: "Maryland", code: "MD" },
      { label: "Massachusetts", code: "MA" },
      { label: "Michigan", code: "MI" },
      { label: "Minnesota", code: "MN" },
      { label: "Mississippi", code: "MS" },
      { label: "Missouri", code: "MO" },
      { label: "Montana", code: "MT" },
      { label: "Nebraska", code: "NE" },
      { label: "Nevada", code: "NV" },
      { label: "New Hampshire", code: "NH" },
      { label: "New Jersey", code: "NJ" },
      { label: "New Mexico", code: "NM" },
      { label: "New York", code: "NY" },
      { label: "North Carolina", code: "NC" },
      { label: "North Dakota", code: "ND" },
      { label: "Ohio", code: "OH" },
      { label: "Oklahoma", code: "OK" },
      { label: "Oregon", code: "OR" },
      { label: "Pennsylvania", code: "PA" },
      { label: "Rhode Island", code: "RI" },
      { label: "South Carolina", code: "SC" },
      { label: "South Dakota", code: "SD" },
      { label: "Tennessee", code: "TN" },
      { label: "Texas", code: "TX" },
      { label: "Utah", code: "UT" },
      { label: "Vermont", code: "VT" },
      { label: "Virginia", code: "VA" },
      { label: "Washington", code: "WA" },
      { label: "West Virginia", code: "WV" },
      { label: "Wisconsin", code: "WI" },
      { label: "Wyoming", code: "WY" },
    ];

    return getStaticResults(results, options);
  }

  // Converts a base 64 encoded byte array and downloads it
  downloadBase64(input: string, fileName: string) {
    const byteCharacters = atob(input);
    const byteArrays: Uint8Array[] = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const type = this.dataType(fileName);
    const blob = new Blob(byteArrays, { type: type.toString() });
    this.downloadBlob(blob, fileName);
  }

  downloadFile(data: string, fileName: string, mimeType: string) {
    const url =
      typeof data === "string"
        ? data
        : window.URL.createObjectURL(new Blob([data], { type: mimeType }));

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  }

  downloadArrayBuffer(data: BlobPart, fileName: string) {
    const type = this.dataType(fileName);
    const blob = new Blob([data], { type: type.toString() });
    this.downloadBlob(blob, fileName);
  }

  downloadBlob(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const adblockEnabled = isAdblockEnabled();

    // if popup is enabled
    if (adblockEnabled) {
      alert("Please disable your Pop-up blocker and try again.");
    } else {
      // Microsoft (IE, Edge) requires separate handling
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-condition
      if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
        (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
      } else {
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }
    }

    getLifecycleSourceProjects() {
        return this.bridge.getLifecycleSourceProjects();
    }

  dataType(fileName: string): string {
    if (fileName.endsWith("png")) {
      return "image/png";
    } else if (fileName.endsWith("xlsx")) {
      return "application/ms-excel";
    } else if (fileName.endsWith("pdf")) {
      return "application/pdf";
    }
    return "";
  }
}
