import { NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { StandardNavLinkDropdownComponentContext } from "../standard-nav-link-dropdown/standard-nav-link-dropdown.component";

export type StandardNavTopActionsContext =
  StandardNavLinkDropdownComponentContext;

@Component({
  standalone: true,
  selector: "standard-nav-top",
  templateUrl: "./standard-nav-top.component.html",
  styles: [
    `
      nav {
        display: flex;
        max-width: 100vw;
        overflow-y: hidden;
        overflow-x: auto;
      }

      ::ng-deep standard-nav-top .nav-item.nav-item-wider .nav-link {
        padding-left: 24px;
        padding-right: 24px;

        font-size: 16px;

        &:not(.active):hover {
          background-color: var(--bs-tertiary-bg);
        }
      }

      :host ::ng-deep {
        .nav-link {
          height: var(--bs-h-header);
          display: inline-flex;
          align-items: center;
          border-radius: 0;
        }

        .nav-link.child-active,
        .nav-link.active {
          background-color: var(--bs-primary) !important;
          color: white !important;
        }

        /* if a nav item is hidden by security, avoid an empty slot */
        .nav-item:empty {
          display: none;
        }

        /* use text-shadow since font-weight changing updates the width */
        /* @supports (font-variation-settings: 'GRAD' 150) {
          .nav-link.child-active,
          .nav-link.active {
            font-variation-settings: 'GRAD' 150;
          }
        }
        @supports not (font-variation-settings: 'GRAD' 150) { */
        /* .nav-link.child-active,
        .nav-link.active {
          text-shadow: -0.06ex 0 0 currentColor, 0.06ex 0 0 currentColor;
        } */
        /* } */

        /* make icons show bigger */
        standard-icon {
          & > svg {
            font-size: 22px;
          }
        }

        // Hide any icons that are present on start actions in the standard-nav-top
        .start-actions-wrapper standard-icon {
          display: none;
        }

        .rounded-button-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          standard-button,
          standard-nav-link,
          standard-nav-link-dropdown {
            button,
            a {
              display: flex;
              align-items: center;
              justify-content: center;

              border-radius: 100px;
              height: 44px;

              background-color: #f8f7f8;
            }
          }
        }
      }
    `,
  ],
  imports: [NgIf, NgTemplateOutlet],
})
export class StandardNavTopComponent {
  @Input() justify: "center" | "between" = "between";

  @ContentChild("logo", { static: false, descendants: false })
  logoTemplate: TemplateRef<never> | null = null;

  @ContentChild("middleLogo", { static: false, descendants: false })
  middleLogoTemplate: TemplateRef<never> | null = null;

  @ContentChild("startActions", { static: false, descendants: false })
  startActionsTemplate: TemplateRef<StandardNavTopActionsContext> | null = null;

  @ContentChild("endActions", { static: false, descendants: false })
  endActionsTemplate: TemplateRef<StandardNavTopActionsContext> | null = null;
}
