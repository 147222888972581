import { Component } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-icon-grid",
  templateUrl: "./icon-grid.component.html",
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
})
export class IconGridComponent {}
