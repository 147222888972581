import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";

@Injectable({
  providedIn: "root",
})
export class RequireAuthenticatedUserGuard implements CanActivate {
  constructor(private msalService: MsalService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> | boolean {
    const account: AccountInfo | undefined =
      this.msalService.instance.getAllAccounts()[0];
    if (!account) {
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }
}
