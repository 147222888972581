<div
  [class]="
    'd-flex flex-column flex-shrink-0 bg-body text-body-secondary h-100 ' +
    (styleClass ?? '')
  "
>
  <ng-container *ngIf="headerTemplate">
    <nav
      [class]="
        'navbar navbar-expand bg-body text-body overflow-hidden flex-shrink-0 ' +
        (headerStyleClass || '')
      "
    >
      <ng-container *ngTemplateOutlet="headerTemplate" />
    </nav>
  </ng-container>

  <ul
    [class]="
      'nav nav-pills flex-column flex-nowrap flex-grow-1 overflow-y-auto ' +
      (startActionsStyleClass || '')
    "
  >
    <ng-container *ngTemplateOutlet="startActionsTemplate" />
  </ul>

  <ng-container *ngIf="endActionsTemplate">
    <ul
      [class]="
        'nav nav-pills flex-column flex-nowrap text-body mt-auto ' +
        (endActionsStyleClass || '')
      "
    >
      <ng-container *ngTemplateOutlet="endActionsTemplate" />
    </ul>
  </ng-container>
</div>
