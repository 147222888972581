import Securable from "./Securable";
import getParentSecurableName from "./getParentSecurableName";
import getExactMatchingSecurables from "./getExactMatchingSecurables";
import areAnySecurablesAllowed from "./areAnySecurablesAllowed";

function isActionAllowed(
  securityRoleIds: number[],
  securableName: string,
  action: string,
  securableData: Securable[],
  exactOnly: boolean = false,
): boolean | undefined {
  // Not sure if it's allowed or not, on purpose
  if (!Array.isArray(securableData) || securableData.length === 0) {
    return undefined;
  }

  // Find all of the exact matches
  const matchingSecurables = getExactMatchingSecurables(
    securityRoleIds,
    securableName,
    action,
    securableData,
  );
  if (Array.isArray(matchingSecurables) && matchingSecurables.length > 0) {
    return areAnySecurablesAllowed(matchingSecurables);
  }

  if (!exactOnly) {
    // If there were no exact matches,
    // recursively check until we find it
    const parentName = getParentSecurableName(securableName);
    if (parentName !== undefined) {
      const isParentActionAllowed = isActionAllowed(
        securityRoleIds,
        parentName,
        action,
        securableData,
        exactOnly,
      );
      return isParentActionAllowed;
    }
  }

  //
  // Not sure if it's allowed or not, on purpose
  //
  return undefined;
}

export default isActionAllowed;
