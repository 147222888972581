<nav class="navbar navbar-expand bg-body text-body border-bottom h-header">
  <ng-container *ngIf="!middleLogoTemplate; else hasMiddleLogo">
    <div class="container-fluid ps-0 justify-content-start">
      <ng-container *ngTemplateOutlet="logoTemplate" />

      <ul
        *ngIf="startActionsTemplate"
        class="navbar-nav align-items-center me-auto start-actions-wrapper"
      >
        <ng-container *ngTemplateOutlet="startActionsTemplate" />
      </ul>

      <ul
        *ngIf="endActionsTemplate"
        class="navbar-nav align-items-center gap-2 ms-auto"
      >
        <ng-container *ngTemplateOutlet="endActionsTemplate" />
      </ul>
    </div>
  </ng-container>

  <ng-template #hasMiddleLogo>
    <div class="container-fluid">
      <div class="row mx-0 align-items-center w-100">
        <div
          [class]="
            'col px-0 d-flex align-items-center flex-nowrap ' +
            (justify === 'between'
              ? 'justify-content-start'
              : 'justify-content-end')
          "
        >
          <ng-container *ngTemplateOutlet="logoTemplate" />

          <ul
            *ngIf="startActionsTemplate"
            [class]="
              'navbar-nav align-items-center gap-2 start-actions-wrapper ' +
              (justify === 'between'
                ? 'justify-content-start text-start'
                : 'justify-content-end text-end')
            "
          >
            <ng-container *ngTemplateOutlet="startActionsTemplate" />
          </ul>
        </div>

        <div class="col-auto">
          <ng-container *ngTemplateOutlet="middleLogoTemplate" />
        </div>

        <div
          [class]="
            'col px-0 d-flex align-items-center flex-nowrap ' +
            (justify === 'between'
              ? 'justify-content-end'
              : 'justify-content-start')
          "
        >
          <ul
            *ngIf="endActionsTemplate"
            [class]="
              'navbar-nav align-items-center gap-2 ' +
              (justify === 'between'
                ? 'justify-content-end text-end'
                : 'justify-content-start text-start')
            "
          >
            <ng-container *ngTemplateOutlet="endActionsTemplate" />
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</nav>
