import { NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from "@angular/router";
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { DataService, DataType, NamingConvention } from "breeze-client";
import dayjs from "dayjs";
import { DialogService } from "primeng/dynamicdialog";
import { StandardLogService } from "src/standard/logging/logging";
import invariant from "tiny-invariant";
import { ServerSidePublicConfigDataService } from "./services/server-side-config-data/server-side-public-config-data.service";
import Utilities from "./services/utilities";
import {
  SecurityService,
  UserSummary,
  userSummary$,
} from "src/standard/security/security.service";
import { HttpCancelService } from "src/standard/services/http-cancel.service";
import { StandardLazyComponent } from "src/standard/standard-lazy-component/standard-lazy-component.component";
import {
  LG,
  MD,
  SM,
  StandardNavShellComponent,
  XL,
  XS,
} from "src/standard/standard-nav-shell/standard-nav-shell.component";
import NProgress from "src/standard/ts/nprogress-customizations";

declare global {
  // eslint-disable-next-line no-var
  var __NG_DIALOG_SERVICE__: DialogService | undefined;
}

export function init(
  serverSidePublicConfigDataService: ServerSidePublicConfigDataService,
) {
  return async () => {
    await serverSidePublicConfigDataService.loadServerSidePublicData();

    return new Promise((resolve, reject) => {
      NamingConvention.camelCase.setAsDefault();
      DataType.parseDateFromServer = function parseDateFromServer(
        source: string,
      ) {
        if (!source) {
          return undefined;
        }

        if (!source.endsWith("Z") && !source.endsWith("z")) {
          source = source + "Z";
        }

        const asDayjs = dayjs(source);
        invariant(asDayjs.isValid(), "Invalid date: " + source);
        return asDayjs.toDate();
      };

      /*
			(<any>breeze.EntityType.prototype).getEntityCtor = function () {
				var origCtor = this.getEntityCtor.apply(this);
		
				var rightsProp = this.getDataProperty("rights");
				if (!rightsProp) {
					this.addProperty(new breeze.DataProperty(this.rights));
					rightsProp = this.getDataProperty("rights");
				}
		
				if (rightsProp.isUnmapped) {
					rightsProp.isUnmapped = false;
				}
		
				return origCtor;
			};
			*/

      // Breeze does a bad thing and uses substr(-1) instead of slice(-1)
      // that is browser inconsistent.  This implementation overrides the
      // default behavior
      // eslint-disable-next-line
      (<any>DataService)._normalizeServiceName = function (
        serviceName: string,
      ) {
        serviceName = serviceName.trim();
        if (serviceName.slice(-1) !== "/") {
          return serviceName + "/";
        } else {
          return serviceName;
        }
      };

      resolve(undefined);
    });
  };
}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  invariant(window.__settings, "No settings set");
  invariant(window.__settings.adTenantId, "No AdTenantId set");
  invariant(window.__settings.adClientId, "No AdClientId set");

  return new PublicClientApplication({
    auth: {
      clientId: window.__settings.adClientId,
      authority: `https://login.microsoftonline.com/${window.__settings.adTenantId}/`,
      redirectUri: `https://${document.location.host}/`,
      postLogoutRedirectUri: `https://${document.location.host}/home/individual`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables native brokering support
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  invariant(window.__settings, "No settings set");
  invariant(window.__settings.adClientId, "No AdClientId set");

  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", [
    "user.read",
  ]);
  protectedResourceMap.set(`https://${document.location.host}/`, [
    `${window.__settings.adClientId}/user_impersonation`,
  ]);

  protectedResourceMap.set(`https://${document.location.host}/api/`, [
    `${window.__settings.adClientId}/user_impersonation`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@Component({
  standalone: true,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
  imports: [
    NgIf,
    NgTemplateOutlet,
    RouterOutlet,

    StandardLazyComponent,
    StandardNavShellComponent,
  ],
})
export class AppComponent implements OnInit {
  XS = XS;
  SM = SM;
  MD = MD;
  LG = LG;
  XL = XL;
  currentYear = new Date().getFullYear();

  constructor(
    private router: Router,
    private logService: StandardLogService,
    private security: SecurityService,
    private httpCancelService: HttpCancelService,
  ) {
    router.events.subscribe((event): void => {
      if (event instanceof NavigationStart) {
        httpCancelService.cancelPendingRequests();
        NProgress.start();

        if (event.url === "/maintenance" || event.url === "/access-denied") {
          userSummary$.next([true, null]);
        }
      } else if (event instanceof NavigationEnd) {
        NProgress.done();
        logService.logPageView(document.title, event.url);
      } else if (event instanceof NavigationCancel) {
        NProgress.done();
      }
    });
  }

  private destroyRef = inject(DestroyRef);
  initializing: boolean = true;
  userSummary: UserSummary | null | undefined;
  isSecurityReady: boolean | undefined;

  ngOnInit(): void {
    this.initializing = true;

    this.userSummary = this.security.userSummary[1];
    userSummary$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isReady, userSummary]) => {
        this.isSecurityReady = isReady;
        this.userSummary = userSummary;

        setTimeout(() => {
          this.onReady();
        });
      });
  }

  async getNavContent() {
    return await import("./nav-content/nav-content.component");
  }

  onReady() {
    // Security not yet ready
    if (!this.isSecurityReady) {
      return;
    }

    if (this.userSummary === undefined) {
      return;
    }

    Utilities.hideLoadingScreen();
    this.initializing = false;
  }
}
