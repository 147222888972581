import { Pipe, PipeTransform } from "@angular/core";
import { NavLinkDropdownItem } from "../standard-nav-link-dropdown/standard-nav-link-dropdown.component";
import { IsActionAllowedPipe } from "./is-action-allowed.pipe";

export function getNumActions(items: NavLinkDropdownItem[]) {
  let numActions = 0;

  for (const item of items) {
    if (item.type === "divider") {
      continue;
    }

    numActions++;

    if (item.items && item.items.length > 0) {
      const numChildActions = getNumActions(item.items);
      numActions += numChildActions;
    }
  }

  return numActions;
}

@Pipe({
  standalone: true,
  name: "filterAllowed",
})
export class FilterAllowedPipe implements PipeTransform {
  constructor(private isActionAllowedPipe: IsActionAllowedPipe) {}

  transform(items: NavLinkDropdownItem[] | undefined): NavLinkDropdownItem[] {
    const filteredItems: NavLinkDropdownItem[] = [];

    if (items && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        const prevItem = items[i - 1];
        const item = items[i]!;
        const nextItem = items[i + 1];

        const canView = this.canView(item);
        if (canView) {
          filteredItems.push(item);
        }
      }
    }

    const dedupdItems: NavLinkDropdownItem[] = [];
    if (filteredItems.length > 0) {
      for (let i = 0; i < filteredItems.length; i++) {
        const prevItem = filteredItems[i - 1];
        const item = filteredItems[i]!;
        const nextItem = filteredItems[i + 1];

        // Only add dividers if it's actually dividing something
        if (item.type === "divider") {
          if (
            prevItem &&
            prevItem.type !== "divider" &&
            nextItem &&
            nextItem.type !== "divider"
          ) {
            dedupdItems.push(item);
          }
        } else {
          dedupdItems.push(item);
        }
      }
    }

    return dedupdItems;
  }

  private canView(item: NavLinkDropdownItem) {
    if (item.type === "divider") {
      return true;
    }

    const children = item.items ?? [];

    // If there's no children, and it's not a link,
    // then it can be shown without any checking
    if (children.length === 0) {
      if (item.to === undefined) {
        return true;
      }
    }

    // If it's a leaf node, check it
    if (children.length === 0 && item.to !== undefined) {
      const canView = this.isActionAllowedPipe.transform(item.to);
      if (canView) {
        return true;
      }
    }

    // If there are children, filter them and
    // see if there's any visible children after being filtered
    if (children.length > 0) {
      const filteredChildren = this.transform(item.items);
      if (filteredChildren.length === 0) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }
}
