export interface ExplicitSecurableElementProps {
  securableName: string;
}

export function isExplicitSecurableElement(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any,
): props is ExplicitSecurableElementProps {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return typeof props === "object" && typeof props.securableName === "string";
}

function getExplicitSecurableName({
  securableName,
}: ExplicitSecurableElementProps) {
  return securableName;
}

export default getExplicitSecurableName;
