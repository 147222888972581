/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { MetadataStore, Entity } from 'breeze-client';
import { Employee/* BEGIN DEMO CODE */, Project, /* END DEMO CODE */ SourceProject} from '../../../model/model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModelBuilderService {
    private metadataStore: MetadataStore | undefined;

    /**
     * Implement extended model properties here.
     * Declare them in modelExtended.d.ts
     */
    constructor() { }

    private computed<T extends Entity, TY>(entity: T, propNames: string[], func: (entity: T) => TY): BehaviorSubject<TY> {
        const subject = new BehaviorSubject<TY>(func(entity));

        for (const prop in entity) {
            if (entity?.[prop] && (<any>entity[prop]).arrayChanged) { //Navigation Property  e.g. <Project>.assignements
                const navPropNameRoot = prop + ".";
                const len = navPropNameRoot.length;
                const navPropNames = propNames.filter(val => val.startsWith(navPropNameRoot)).map(val => val.substr(len)); //Filter to Attributes on Nav Property. e.g. assignments.dueDate => dueDate

                if (navPropNames.length) {
                    (<Entity[]>(<unknown>entity[prop])).forEach(item => { 
                        item.entityAspect.propertyChanged.subscribe(p => { //subscribe to propertyChanged on each existing item in the nav property array
                            if (navPropNames.find(n => n === p.propertyName)) {
                                subject.next(func(entity));
                            }
                        });
                    });
                }

                (<any>entity[prop]).arrayChanged.subscribe((p: any) => {  //if nav property array changes then need to subscribe to property changed on new items and unsubscribe from removed items
                    if (propNames.find(n => n === (<any>p.array).navigationProperty.name)) {
                        subject.next(func(p.array.parentEntity));
                    }

                    if (navPropNames.length) {
                        p.added.forEach((item: any) => {
                            item.entityAspect.propertyChanged.subscribe((x: any) => {
                                if (navPropNames.find(n => n === x.propertyName)) {
                                    subject.next(func(entity));
                                }
                            });
                        });

                        p.removed.forEach((item: any) => {
                            item.entityAspect.propertyChanged.unsubscribe();
                        });
                    }
                });
            }
        }

        //subscribe to property changed on entity
        entity.entityAspect.propertyChanged.subscribe(p => {
            if (propNames.find(n => n === p.propertyName)) {
                subject.next(func(entity));
            }
        });

        return subject;
    }

    //private extendEmployee() {
    //    const myEntityCtor = function () { };

    //    const myEntityInitializer = (myEntity: Employee) => {
    //        myEntity.fullName = this.computed(myEntity, ["firstName", "lastName"], (e) => `${e.firstName} ${e.lastName}`);
    //    };

    //    this.metadataStore!.registerEntityTypeCtor('Employee', myEntityCtor, myEntityInitializer as any);
    //}
    /* BEGIN DEMO CODE */

    //private extendProject() {
    //    const myEntityCtor = function () {
    //        // @ts-ignore
    //        // eslint-disable-next-line @typescript-eslint/no-invalid-this
    //        (this as any).modify = false;
    //    };

    //    const myEntityInitializer = (myEntity: Project) => {
    //        myEntity.ownerName = this.computed(myEntity, ["owner"], (e) => e.owner ? `${e.owner.firstName} ${e.owner.lastName}` : '');

    //        myEntity.oldestAssignmentDate = this.computed(
    //            myEntity,
    //            ["assignments", "assignments.dueDate"],
    //            (e) => new Date(Math.min.apply(Math, e.assignments.map(as => as.dueDate.getTime())))
    //        );
    //    };

    //    this.metadataStore!.registerEntityTypeCtor('Project', myEntityCtor, myEntityInitializer as any);
    //}

    private extendSourceProject() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: SourceProject) => {
            myEntity.projectDisplayName = this.computed(myEntity, ["ProjectName", "ProjectNumber"], (e) => `${e.projectName} - ${e.projectNumber}`);
        };

        this.metadataStore!.registerEntityTypeCtor('SourceProject', myEntityCtor, myEntityInitializer as any);
    }

    /* END DEMO CODE */
    extendMetadata(metadataStore: MetadataStore) {
        this.metadataStore = metadataStore;

        this.extendSourceProject();
        /* END DEMO CODE */
    }
}
