import { uid } from "uid";
import {
  DURATION_MS_TOAST_BUSY_DELAY,
  DURATION_MS_TOAST_ERROR,
  DURATION_MS_TOAST_MIN,
  DURATION_MS_TOAST_SUCCESS,
} from "src/config";

const TOAST_SEVERITIES = ["Success", "Error", "Busy"] as const;
export type ToastSeverity = (typeof TOAST_SEVERITIES)[number];
export type ToastKey = string;

export interface IToast {
  key: ToastKey;
  el: HTMLElement;
  dismiss: () => void;
}

export function createElement(type: string) {
  return document.createElement(type);
}

const current: {
  pendingBusyToasts: Record<ToastKey, IToast>;
} = { pendingBusyToasts: {} };
export function toast(severity: ToastSeverity, detail: string): ToastKey {
  const container = createContainerIfNeeded();
  const iToast = createToast(container, severity, detail);

  if (severity === "Busy") {
    current.pendingBusyToasts[iToast.key] = iToast;

    // Delay a bit before actually showing, in case it's done before it has a chance to even show!
    setTimeout(() => {
      if (current.pendingBusyToasts[iToast.key]) {
        appendToast(container, iToast.el);
      }
    }, DURATION_MS_TOAST_BUSY_DELAY);
  } else {
    appendToast(container, iToast.el);

    const duration =
      severity === "Success"
        ? DURATION_MS_TOAST_SUCCESS
        : DURATION_MS_TOAST_ERROR;
    setTimeout(function () {
      removeToast(container, iToast.el);
    }, duration);
  }

  return iToast.key;
}

export function hideToast(toastKey: ToastKey) {
  const container = document.querySelector(".toast-container");
  if (!container) {
    return;
  }

  if (toastKey && current.pendingBusyToasts[toastKey]) {
    delete current.pendingBusyToasts[toastKey];
  }

  const children = Array.from(container.children) as HTMLElement[];
  const el = children.find((x) => x.dataset.toastKey === toastKey);
  if (!el) {
    return;
  }

  // If the toast was shown/hidden too fast, we want to avoid flashing the thingy too quickly
  const startMs = el.dataset.showTime ? Number(el.dataset.showTime) : undefined;
  if (startMs === undefined) {
    return;
  }
  const nowMs = new Date().getTime();
  const remainingMinMs = DURATION_MS_TOAST_MIN - (nowMs - startMs);
  if (remainingMinMs > 0) {
    setTimeout(() => {
      removeToast(container, el);
    }, remainingMinMs);
  } else {
    removeToast(container, el);
  }
}

export function clearToastContainer() {
  const container = document.querySelector(".toast-container");
  if (!container) {
    return;
  }

  Array.from(container.children).forEach((el) => {
    removeToast(container, el as HTMLElement);
  });
}

function removeToast(container: Element, toastEl: HTMLElement): void {
  try {
    container.removeChild(toastEl);
  } catch (error) {
    //this.logger.logException(error);
    // ignore error, likely timing issue
  }
}

function appendToast(container: Element, toastEl: HTMLElement) {
  toastEl.dataset.showTime = `${new Date().getTime()}`;

  container.appendChild(toastEl);
}

function createContainerIfNeeded(): Element {
  let container = document.querySelector(".toast-container");

  if (!container) {
    container = createElement("div");
    container.classList.add(
      "toast-container",
      "d-flex",
      "flex-column",
      "align-items-end",
    );
    document.body.appendChild(container);
  }

  return container;
}

function createToast(
  container: Element,
  severity: ToastSeverity,
  message: string,
): IToast {
  const toastKey = uid() as ToastKey;
  const wrapper = createElement("div");
  const el = createElement("div");

  wrapper.classList.add("toast-wrapper");
  wrapper.dataset.severity = severity;
  wrapper.dataset.toastKey = toastKey;
  wrapper.appendChild(el);

  el.classList.add("toast", severity);
  el.setAttribute("role", "alert");

  const toastContent = createElement("div");
  toastContent.classList.add("d-flex");

  const icon = createElement("i");
  icon.classList.add(
    "me-2",
    "pi",
    severity === "Success" ? "pi-check" : "pi-exclamation-circle",
  );
  toastContent.appendChild(icon);

  // Content
  const messageEl = createElement("div");
  messageEl.classList.add("col");
  messageEl.innerHTML = `<strong>${severity}</strong><br />${message}`;
  toastContent.appendChild(messageEl);

  el.appendChild(toastContent);

  const toastEl: IToast = {
    key: toastKey,
    el: wrapper,
    dismiss() {
      removeToast(container, wrapper);
    },
  };

  const closeIcon = createDismissButton(toastEl);
  toastContent.appendChild(closeIcon);

  return toastEl;
}

function createDismissButton(toastEl: IToast): Element {
  const btn = createElement("button");
  btn.classList.add("toast__dismiss");

  const icon = createElement("i");
  icon.classList.add("pi", "pi-times");
  btn.appendChild(icon);

  btn.addEventListener(
    "click",
    function () {
      toastEl.dismiss();
    },
    { once: true },
  );

  return btn;
}
