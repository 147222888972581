<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
>
  <g id="grid" transform="translate(-25 -23)">
    <path
      id="Path_399"
      data-name="Path 399"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(25 23)"
      fill="#333"
    />
    <path
      id="Path_398"
      data-name="Path 398"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(33 23)"
      fill="#333"
    />
    <path
      id="Path_397"
      data-name="Path 397"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(41 23)"
      fill="#333"
    />
    <path
      id="Path_396"
      data-name="Path 396"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(25 31)"
      fill="#333"
    />
    <path
      id="Path_395"
      data-name="Path 395"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(33 31)"
      fill="#333"
    />
    <path
      id="Path_394"
      data-name="Path 394"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(41 31)"
      fill="#333"
    />
    <path
      id="Path_393"
      data-name="Path 393"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(25 39)"
      fill="#333"
    />
    <path
      id="Path_392"
      data-name="Path 392"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(33 39)"
      fill="#333"
    />
    <path
      id="Path_391"
      data-name="Path 391"
      d="M2,0H4A2,2,0,0,1,6,2V4A2,2,0,0,1,4,6H2A2,2,0,0,1,0,4V2A2,2,0,0,1,2,0Z"
      transform="translate(41 39)"
      fill="#333"
    />
  </g>
</svg>
