import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import Utilities from "src/app/services/utilities";
import * as Config from "src/config";
import { ServerSideConfigData } from "src/model/modelDto";
import invariant from "tiny-invariant";

@Injectable({
  providedIn: "root",
})
export class ServerSideConfigDataService {
  configData: ServerSideConfigData | undefined;
  configDataLoaded$ = new ReplaySubject<boolean>(1);

  constructor(private http: HttpClient) {}

  loadServerSideData(): Promise<boolean> {
    if (!this.configData) {
      return this.http
        .get<ServerSideConfigData>(
          `${Config.serviceName}/getServerSideConfigData`,
        )
        .toPromise()
        .then((result) => {
          invariant(result !== undefined);
          this.configData = result;

          invariant(result.hashes[0]);

          Config.hashes.currentMetadataHash = result.hashes[0].metadataHash;
          Config.hashes.currentAnnotatedMetadataHash =
            result.hashes[0].annotatedMetadataHash;
          Config.hashes.currentLookupsHash = result.hashes[0].lookupsHash;

          this.configDataLoaded$.next(true);
          return true;
        })
        .catch((err) => Promise.reject(err));
    } else {
      return Utilities.valueAsPromise(true);
    }
  }
}
