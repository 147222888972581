import { Pipe, PipeTransform } from "@angular/core";
import { querystring } from "src/standard/ts/formUtils";

@Pipe({
  name: "queryParams",
  standalone: true,
})
export class QueryParamsPipe implements PipeTransform {
  transform(
    queryParams: Parameters<typeof querystring>[0] | undefined,
  ): unknown {
    if (!queryParams) {
      return "";
    }

    const qs = querystring(queryParams);
    return qs ? "?" + qs : "";
  }
}
