import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { IsActionAllowedPipe, isExternalUrl } from "./is-action-allowed.pipe";
import { getSecurableName } from "./security.service";

export type SecurableName = string;
export type SecurableAction = "Read" | "Create" | "Update" | "Delete";

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[securable]",
  providers: [IsActionAllowedPipe],
})
export class SecurableDirective implements OnInit, AfterViewInit {
  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<never>,
    private isActionAllowedPipe: IsActionAllowedPipe,
  ) {
    this.show();
  }

  @Input() securableEnabled: boolean = true;
  @Input() securable: SecurableName | undefined;
  @Input() securablePart: SecurableName | undefined;
  @Input() securableAction: SecurableAction | undefined;

  _isFirst: boolean = true;
  _isShowing: boolean | undefined;

  get nativeElement() {
    const element = this.view.element as
      | ElementRef<NonDocumentTypeChildNode>
      | undefined;
    const nativeElement = element?.nativeElement;
    return nativeElement;
  }

  get innerTag() {
    const aTag = this.nativeElement?.previousElementSibling as
      | HTMLAnchorElement
      | HTMLElement
      | undefined;
    return aTag;
  }

  get innerChildTag() {
    const aTag = this.innerTag?.firstElementChild as
      | HTMLAnchorElement
      | HTMLElement
      | undefined;
    return aTag;
  }

  get tag(): HTMLAnchorElement | HTMLElement | undefined {
    if (this.innerTag && "href" in this.innerTag) {
      return this.innerTag;
    }

    if (this.innerChildTag && "href" in this.innerChildTag) {
      return this.innerChildTag;
    }

    return this.innerTag;
  }

  get href() {
    if (this.tag && "href" in this.tag) {
      const href = this.tag.href.replace(location.origin, "");
      if (href === "/") {
        return undefined;
      }

      return href;
    }

    return undefined;
  }

  get text() {
    if (this.tag) {
      return this.tag.textContent?.trim() ?? undefined;
    }

    return undefined;
  }

  get securableName() {
    let securableName: string | undefined;
    if (this.securable) {
      securableName = this.securable;
    } else if (this.securablePart) {
      securableName = getSecurableName(
        location.href + "." + this.securablePart,
      );
    } else if (this.href) {
      securableName = getSecurableName(this.href);
    } else {
      // If there's no text, don't add a securable
      const text = this.text;
      if (text) {
        securableName = getSecurableName(location.href + "." + text);
      }
    }

    return securableName;
  }

  ngOnInit() {
    this.showHide();
  }

  isReady: boolean = false;
  ngAfterViewInit(): void {
    this.isReady = true;
    this.showHide();
  }

  private showHide() {
    const isEnabled =
      this.securableEnabled === true && this.securablePart === undefined;
    if (!isEnabled) {
      this.show();
      this.removeDebugStyles();
      return;
    }

    const securableName = this.securableName;
    const actionName = this.securableAction;

    if (this.isReady) {
      if (securableName) {
        if (
          isExternalUrl(this.href) ||
          this.isActionAllowedPipe.transform(
            securableName,
            actionName ?? "Read",
          )
        ) {
          this.show();
        } else {
          this.hide();
        }

        this.removeDebugStyles();

        return;
      } else {
        this.addDebugStyles(securableName);
      }
    }
  }

  private show() {
    if (!this._isShowing) {
      this.view.createEmbeddedView(this.template);
      this._isShowing = true;
    }
  }

  private hide() {
    this.view.clear();
    this._isShowing = false;
  }

  private origTitle: string = "";
  private origBg: string = "";
  private debugTimeout: ReturnType<typeof setTimeout> | undefined;

  private addDebugStyles(securableName: string | undefined) {
    return; // TODO: need to make this work with pTooltip to ensure icon-only buttons can be supported here

    // if (window.__settings?.environment !== "Local") {
    //   return;
    // }

    // if (this.innerChildTag && securableName) {
    //   this.innerChildTag.title = securableName;
    // } else if (this.innerTag && securableName) {
    //   this.innerTag.title = securableName;
    // }

    // this.clearDebugTimeout();

    // if (!this.securableEnabled) {
    //   return;
    // }

    // let tag = this.tag;
    // if (tag) {
    //   this.debugTimeout = setTimeout(() => {
    //     if (!this.securableEnabled) {
    //       return;
    //     }

    //     tag = this.tag;
    //     if (tag) {
    //       this.origTitle = tag.title;
    //       this.origBg = tag.style.backgroundColor;

    //       if ("href" in tag) {
    //         (tag as HTMLAnchorElement).title =
    //           "Could not find href on *securable to use as securable";
    //       } else {
    //         tag.title = "Could not find securable name on *securable";
    //       }

    //       tag.style.backgroundColor = "#FF00FF";
    //     }
    //   }, 0);
    // }
  }

  private removeDebugStyles() {
    if (window.__settings?.environment !== "Local") {
      return;
    }

    this.clearDebugTimeout();

    const tag = this.tag;
    if (tag) {
      tag.title = this.securableName || this.origTitle;
      tag.style.backgroundColor = this.origBg;
    }
  }

  private clearDebugTimeout() {
    if (this.debugTimeout) {
      clearTimeout(this.debugTimeout);
      this.debugTimeout = undefined;
    }
  }
}
