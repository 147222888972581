import { NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { StandardNavLinkDropdownComponentContext } from "../standard-nav-link-dropdown/standard-nav-link-dropdown.component";

export type StandardNavSideActionsContext =
  StandardNavLinkDropdownComponentContext;

@Component({
  standalone: true,
  selector: "standard-nav-side",
  templateUrl: "./standard-nav-side.component.html",
  imports: [NgIf, NgTemplateOutlet],
})
export class StandardNavSideComponent {
  @Input() styleClass: string | undefined;
  @Input() headerStyleClass: string | undefined;
  @Input() startActionsStyleClass: string | undefined;
  @Input() endActionsStyleClass: string | undefined;

  @ContentChild("header", { static: false, descendants: false })
  headerTemplate: TemplateRef<never> | null = null;

  @ContentChild("startActions", { static: false, descendants: false })
  startActionsTemplate: TemplateRef<StandardNavSideActionsContext> | null = null;

  @ContentChild("endActions", { static: false, descendants: false })
  endActionsTemplate: TemplateRef<StandardNavSideActionsContext> | null = null;
}
