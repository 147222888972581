import { Pipe, PipeTransform } from "@angular/core";
import { SecurityService } from "./security.service";
import { SecurableAction } from "./securable.directive";

export function isExternalUrl(url: string | null | undefined) {
  if (!url) {
    return false;
  }

  if (url.startsWith(location.origin)) {
    return false;
  }

  if (url === "#") {
    return true;
  }

  if (
    url.startsWith("https://") ||
    url.startsWith("http://") ||
    url.startsWith("mailto://")
  ) {
    return true;
  }

  return false;
}

@Pipe({
  standalone: true,
  name: "isActionAllowed",
})
export class IsActionAllowedPipe implements PipeTransform {
  constructor(private securityService: SecurityService) {}

  transform(
    securableName: string,
    action: SecurableAction = "Read",
    securityRoleIds: number[] | undefined = undefined,
  ) {
    if (isExternalUrl(securableName)) {
      return true;
    }

    return (
      this.securityService.isActionAllowedSync(
        securableName,
        action,
        securityRoleIds,
      ) ?? false
    );
  }
}
