import { NgModule } from "@angular/core";
import { IsActionAllowedPipe } from "./is-action-allowed.pipe";
import { SecurableDirective } from "./securable.directive";
import { FilterAllowedPipe } from "./filter-allowed.pipe";

@NgModule({
  imports: [IsActionAllowedPipe, FilterAllowedPipe, SecurableDirective],
  exports: [SecurableDirective, IsActionAllowedPipe, FilterAllowedPipe],
  providers: [IsActionAllowedPipe, FilterAllowedPipe],
})
export class SecurityModule {}
